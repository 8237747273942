<template>
  <Popover class="fixed left-0 w-full top-0 z-50 bg-white shadow">
    <div class="max-w-7xl mx-auto px-4 sm:px-6">
      <div
        class="
          grid 
          grid-cols-2
          justify-between
          items-center
          py-2
          md:justify-start
        "
      >
        <div class="">
          <router-link to="/">
            
            <div class="w-4/5 hidden md:block">
              <img src="@/assets/logo-desktop.svg" class="my-4 w-full">
            </div>
            <div class="w-3/4 block md:hidden">
              <img src="@/assets/logo-mobile.svg" class="my-4 h-8">
            </div>
          </router-link>
        </div>
        <div class="-mr-2 -my-2 ml-auto md:hidden">
          <PopoverButton
            class="
              rounded-md
              p-2
              inline-flex
              items-center
              justify-center
              text-white
              hover:text-gray-500
              transition
              hover:bg-white
            "
          >
            <span class="sr-only">Open menu</span>
            <MenuIcon class="h-6 w-6 text-accent" aria-hidden="true" />
          </PopoverButton>
        </div>
        <PopoverGroup as="nav" class="hidden md:flex space-x-6 xl:space-x-7 ml-auto ">
          <NavItem
            v-for="item in navigation"
            :path="item.path"
            :key="item.label"
            :dropdownItems="item.dropdownItems"
          >
            {{ item.label }}
          </NavItem>
        </PopoverGroup>
      </div>
    </div>

    <transition
      enter-active-class="duration-200 ease-out"
      enter-from-class="opacity-0 scale-95"
      enter-to-class="opacity-100 scale-100"
      leave-active-class="duration-100 ease-in"
      leave-from-class="opacity-100 scale-100"
      leave-to-class="opacity-0 scale-95"
    >
      <PopoverPanel
        focus
        class="
          absolute
          top-0
          inset-x-0
          z-10
          p-2
          transition
          transform
          origin-top-right
          md:hidden
        "
      >
        <div
          class="
            rounded-lg
            shadow-lg
            ring-1 ring-black ring-opacity-5
            bg-white
            divide-y-2 divide-gray-50
          "
        >
          <div class="pt-5 pb-6 px-5">
            <div class="flex items-center justify-between">
              <router-link to="/">
                <img src="@/assets/logo-mobile.svg" class="my-4 h-8">
              </router-link>
              <div class="-mr-2">
                <PopoverButton
                  class="
                    rounded-md
                    p-2
                    inline-flex
                    items-center
                    justify-center
                    transition
                    hover:bg-accent-lightest
                  "
                >
                  <span class="sr-only">Close menu</span>
                  <XIcon class="h-6 w-6 text-accent" aria-hidden="true" />
                </PopoverButton>
              </div>
            </div>
            <div class="mt-6">
              <nav class="grid gap-y-8">
                <PopoverButton>
                  <router-link
                    v-for="item in navigation"
                    :key="item.label"
                    :to="item.path"
                    class="nav-item-mobile"
                  >
                    <span class="nav-item-mobile__text">{{ item.label }}</span>
                  </router-link>
                </PopoverButton>
              </nav>
            </div>
          </div>
        </div>
      </PopoverPanel>
    </transition>
  </Popover>
  <div class="nav-gap" />
</template>

<script>
import NavItem from "@/components/nav/NavItem.vue";
import {
  Popover,
  PopoverButton,
  PopoverGroup,
  PopoverPanel,
} from "@headlessui/vue";
import { MenuIcon, XIcon } from "@heroicons/vue/outline";
import { navigation } from "@/data";

export default {
  components: {
    Popover,
    PopoverButton,
    PopoverGroup,
    PopoverPanel,
    MenuIcon,
    XIcon,
    NavItem,
  },
  setup() {
    return { navigation };
  },
};
</script>
