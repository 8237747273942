<template>
  <footer class="bg-white border-t" aria-labelledby="footer-heading">
    <h2 id="footer-heading" class="sr-only">Footer</h2>
    <div class="max-w-7xl mx-auto py-12 pb-8 px-4 sm:px-6 lg:px-8">
      <div class="grid md:grid-cols-2 gap-8">
        <router-link to="/">
        <img src="@/assets/Hewings-Logo.jpg" class="my-4 w-3/4 md:w-2/4">
        </router-link>
        <div class="grid sm:grid-cols-2 gap-8">
          <div>
            <h3 class="mb-3 footer-heading">Navigation</h3>
            <div class="space-y-1">
              <router-link
                v-for="route in navigation"
                :key="route.label"
                class="
                  block
                  with-underline
                  w-max
                  transition
                  hover:text-accent
                  capitalize
                "
                :to="route.path"
                >{{ route.label }}</router-link
              >
            </div>
          </div>
          <div>
            <h3 class="mb-3 footer-heading">Contact Us</h3>
            <div class="space-y-2">
              <address class="not-italic">
                35 Sharp Rd <br/>Brantford ON N3T 5L8

              </address>
              <a
                href="tel:519-752-8478"
                class="block with-underline w-max hover:text-accent"
                ><span class="font-medium">Phone:</span> 519-752-8478
              </a>
              <a
                href="tel:519-751-7762"
                class="block with-underline w-max hover:text-accent"
                ><span class="font-medium">Fax:</span> 519-751-7762
              </a>
              <a
                href="mailto:operations@hewings.ca"
                class="block with-underline w-max hover:text-accent"
                ><span class="font-medium">Email:</span> operations@hewings.ca</a
              >
            </div>
          </div>
        </div>
      </div>
      <div
        class="
          mt-8
          pt-8
          border-t border-gray-200
          
        "
      >
        <p class="text-base text-gray-400 text-center">
          &copy; Copyright {{currentYear}} Hewing Transportation Inc.
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
import { navigation } from "@/data";

export default {
  setup() {
    return { 
      navigation,
      currentYear: new Date().getFullYear(),
      };
  },
};
</script>
